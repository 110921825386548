<template>
    <section id="contact">
        <figure class="circle-brush"><img :src='this.$pathprod+"images/contact/cercle-brush.svg"' alt="cercle-brush"></figure>
        <div class="contact-ctn">
            <h1 style="display: none;">Harmony</h1>
            <h2 class="title-h2 text-focus-in">Contacts</h2>
            <div class="contact-list">
                <div class="contact-item">
                    <figure class="contact-logo">
                        <img :src='this.$pathprod+"images/contact/bnp-paribas.svg"' alt="BNP Paribas Real Estate">
                    </figure>
                    <div>
                        <p>Anne-Lise Clauzet <br><strong>06 09 39 88 49</strong><br>anne-lise.clauzet@realestate.bnpparibas</p>
                        <p>Guillaume Garcia <br><strong>06 19 92 83 51</strong><br>guillaume.garcia@realestate.bnpparibas</p>
                    </div>
                </div>

                <div class="contact-item">
                    <figure class="contact-logo">
                        <img :src='this.$pathprod+"images/contact/CBRE.svg"' alt="CBRE">
                    </figure>
                    <div>
                        <p>Pierre-Germain Bonnaud<br><strong>06 07 56 34 55</strong><br>pg.bonnaud@cbre.fr</p>
                        <p>Bertrand Leduc<br><strong>06 08 18 40 17</strong><br>bertrand.leduc@cbre.fr</p>
                    </div>
                </div>

                <div class="contact-item">
                    <figure class="contact-logo">
                        <img :src='this.$pathprod+"images/contact/colliers-international.svg"' alt="colliers-international">
                    </figure>
                    <div>
                        <p>Sophie Walrave<br><strong>06 75 47 58 43</strong><br>sophie.walrave@colliers.com</p>
                        <p>Franck Torus<br><strong>06 31 15 79 40</strong><br>franck.torus@colliers.com</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'Contact',
        props: {
            msg: String,
        },

        metaInfo() {
            return { 
                title: "Harmony - Contact",
                meta: [
                    { name: 'description', content: 'Pour toutes questions et renseignements concernant HARMONY - Saint-Ouen.'},
                    { property: 'og:title', content: "Harmony - Contact"},
                    { property: 'og:site_name', content: 'Harmony'},
                    { property: 'og:type', content: 'website'},    
                ]
            }
        },
    }
</script>